<template>
  <div>
    <a-table ref="TableInfo" size="small" :bordered="true"
             :columns="columns"
             rowKey="id"
             :dataSource="dataSource"
             :pagination="pagination"
             @change="handleTableChange"
             :loading="loading"
             :scroll="{y:400}">
      <template slot="topicOptions" slot-scope="text, record">
        <div v-for="item in record.optionsList" :key="item.index">
          {{ `${item.index}->选项【${item.option}】，分数（${item.score}）` }}
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" size="small" style="margin-right:15px;" icon="edit" @click="modify(record)">修改
        </a-button>
        <a-button type="danger" size="small" @click="deleteFactor(record)" icon="delete">删除</a-button>
      </template>
    </a-table>
    <div style="margin:20px 20px;">
      <a-button type="success" size="small" @click="search(1)" icon="reload" style="margin-right:20px">刷新</a-button>
      <a-button type="primary" size="small" @click="add" icon="plus">新增题目</a-button>
      <a-button type="default" size="small" @click="showImport" icon="import" style="margin-left:20px">从 Excel 导入题目
      </a-button>
    </div>
    <add-topic-modal ref="addTopicModal" @success="editSuccess"/>
    <topic-import-modal ref="topicImportModal" @success="editSuccess"/>
  </div>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import AddTopicModal from "./AddTopicModal";
import TopicImportModal from './TopicImportModal'

export default {
  name: "EvaluationTopicList",
  mixins: [ListMixin],
  components: {AddTopicModal, TopicImportModal},
  data() {
    return {
      listGetUrl: "evaluation/topicList",
      listMethod: "get",
      evaId: undefined
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: '排序号',
          width: 80,
          dataIndex: 'sortNumber',
          align: 'center',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'sortNumber' && sortedInfo.order
        },
        {
          title: '题目标题',
          // align: 'center',
          width: 300,
          dataIndex: 'topicTitle',
        },
        // {
        //   title: '题目类型',
        //   align: 'center',
        //   width: 100,
        //   dataIndex: 'topicType',
        // },
        {
          title: '结果因子',
          dataIndex: 'factorName',
          align: 'center',
          width: 120,
        },
        {
          title: '选项答案',
          align: 'center',
          dataIndex: 'topicOptions',
          scopedSlots: {customRender: 'topicOptions'}
        }, {
          title: '操作',
          width: 250,
          align: 'center',
          // fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }]
    }
  },
  methods: {
    init(evaId) {
      this.evaId = evaId;
      this.queryParams.evaId = evaId;
      this.search(1)
    },
    modify(record) {
      this.$refs.addTopicModal.showTopicModal(record, 'edit')
    },
    add() {
      this.$refs.addTopicModal.showTopicModal({evaId: this.evaId}, 'new')
    },
    showImport() {
      this.$refs.topicImportModal.showModal(this.evaId);
    },
    deleteFactor(record) {
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.factorName + "】吗？！",
        centered: true,
        onOk() {
          that.$post("evaluation/deleteTopic", {evaId:record.evaId,topicId: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search(1);
          });
        },
      });
    },
    afterFetch(data) {
      data.records.forEach((v, index) => {
        v.optionsList = JSON.parse(v.topicOptions || '[]')
      })
    },
    editSuccess() {
      this.search(1)
    }
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
