<template>
  <a-modal
      v-drag-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :title="title"
      :width="1100"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="题目"
          prop="topicTitle" required
          v-bind=" formItemLayout">
        <a-input v-model="form.topicTitle" v-decorator="['topicTitle']" placeholder="请输入题目" allow-clear/>
      </a-form-model-item>
      <a-form-model-item
          label="结果因子"
          prop="factor"
          v-bind="formItemLayout">
        <div style="width:500px;display: flex">
          <a-select :options="factorList" v-model="form.factorId" allow-clear v-decorator="['factorId']"
                    placeholder="结果因子"
                    @change="masterFactorChanged"
          ></a-select>
          <div style="font-size:0.8em;color:#FF9900;margin-top:-5px;white-space: nowrap;margin-left:10px;">
            此处选择了结果因子，则下面的所有选项都是同一个结果因子！
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="答案选项" v-bind="formItemLayout" prop="topicOptions">
        <div class="div-options">
          <div class="one-option" v-for="(item,index) in optionList" :key="index">
            <div style="width:400px">
              <a-input v-model="item.option" placeholder="选项描述"></a-input>
            </div>
            <div style="width:100px">
              <a-input-number v-model="item.score" placeholder="答案分数"></a-input-number>
            </div>
            <div style="width:150px">
              <a-select :options="factorList" v-model="item.factor" allow-clear placeholder="结果因子"
                        :disabled="form.factorId>0"></a-select>
            </div>
            <div style="margin-left:20px">
              <a-button size="small" type="danger" icon="delete" @click="optionList.splice(index,1)">删除</a-button>
              <a-button size="small" type="default" icon="arrow-up" style="margin-left:20px;"
                        @click="moveUp(item,index)">上移
              </a-button>
              <a-button size="small" type="default" icon="arrow-down" style="margin-left:5px;"
                        @click="moveDown(item,index)">下移
              </a-button>
            </div>
          </div>
          <a-button size="small" type="primary" icon="plus" @click="addOption">增加选项</a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item label="排序号" v-bind="{
        labelCol: {span: 3},
        wrapperCol: {span: 4},
      }" prop="sortNumber">

        <div style="width:270px;display: flex">
          <a-input-number v-model="form.sortNumber" :step="10" :min="10" v-decorator="['sortNumber',
                   {initialValue: 10}]" style="width:100px;"/>
          <div style="font-size:0.8em;color:#FF9900;margin-top:-5px;white-space: nowrap;margin-left:10px;">数字越大排序越后
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>

export default {
  name: "AddTopicModal",
  data() {
    return {
      action: 'edit',
      formItemLayout: {
        labelCol: {span: 3},
        wrapperCol: {span: 21},
      },
      title: "",
      visible: false,
      confirmLoading: false,
      record: {},
      form: {},
      factorList: [],
      optionList: [],
      rules: {
        topicTitle: [
          {
            required: true,
            message: "请输入题目",
            trigger: "blur",
          }
        ],
        topicOptions: [
          {
            validator: (rule, value, callback) => { //此处必须用箭头函数，否则下面的this指向函数本身
              let optionList = this.optionList || [];
              this.form.topicOptions = JSON.stringify(optionList)
              debugger
              if (optionList.length < 2) {
                callback(new Error("请至少定义两个有效答案"));
              } else {
                optionList.forEach((v, index) => {
                  if (!v.option && v.score === undefined && !v.factor) {
                    callback(new Error("第" + (index + 1) + "答案数据不全"));
                    return
                  }
                })
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      editMode: false,
      defaultFactor: ''
    };
  },

  methods: {
    async showTopicModal(record, action) {
      this.visible = true;
      this.action = action || 'edit';
      this.record = record || {}
      let res = await this.$get("evaluation/factorList", {evaId: record.evaId})
      let factorList = ((res.data && res.data.records) || []).map(v => {
        return {
          value: v.id,
          label: v.factorName + '（' + v.factorNo + '）'
        }
      })
      this.factorList = [{value: 0, label: '--请选择--'}].concat(factorList)
      this.defaultFactor = this.factorList.length > 0 ? this.factorList[0].value : ''

      if (this.action == 'edit') {
        this.form = {
          ...record,
        };
        this.title = "修改题目：" + record.id;
        this.optionList = JSON.parse(record.topicOptions || "[]")
      } else {
        this.form = {
          ...(record || {}),
          sortNumber: 10
        };
        this.title = "新增题目";
      }
      if (this.optionList.length == 0) {
        this.optionList.push({index: this.optionList.length, option: '', score: '', factor: this.defaultFactor})
        this.optionList.push({index: this.optionList.length, option: '', score: '', factor: this.defaultFactor})
        this.optionList.push({index: this.optionList.length, option: '', score: '', factor: this.defaultFactor})
      }
    },
    masterFactorChanged(e) {
      if (e > 0) {
        this.defaultFactor = e;
        this.optionList.forEach(v => {
          v.factor = e
        })
      }
    },
    addOption() {
      this.optionList.push({index: this.optionList.length, option: '', score: '', factor: this.defaultFactor})
    },
    moveUp(item, index) {
      if (index == 0) {
        return;
      }
      this.optionList[index - 1].index = index
      this.optionList[index].index = index - 1
      this.optionList.sort((a, b) => {
        return a.index - b.index
      })
    },
    moveDown(item, index) {
      if (index == this.optionList.length - 1) {
        return;
      }
      this.optionList[index + 1].index = index
      this.optionList[index].index = index + 1
      this.optionList.sort((a, b) => {
        return a.index - b.index
      })
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("evaluation/saveTopic", this.form).then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$message.success("保存成功");
            this.$emit("success", res.data);
          }).catch(e => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

.tip1 {
  color: #FF9900;
  margin: 0px 80px 20px;
}

.div-options {
  .one-option {
    display: flex;
    align-items: center;
  }
}

</style>
